import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from '../../util/reactIntl';

import css from './VerificationBadges.module.css';
import IconBadge from '../IconBadge/IconBadge';

export const VerificationBadgesComponent = props => {
  const { user } = props;

  const isUserVerified = user?.attributes?.profile?.publicData?.isVerified;

  return (
    <span className={css.badgeIcons}>
      {/* <IconBadge width={'1.5rem'} height={'1.5rem'} isVerified={false} /> */}
      {isUserVerified && <IconBadge width={'1.5rem'} height={'1.5rem'} isVerified={true} />}
    </span>
  );
};

VerificationBadgesComponent.propTypes = {
  user: PropTypes.object.isRequired,
};

export default injectIntl(VerificationBadgesComponent);
