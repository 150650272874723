import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

import css from './IconBadge.module.css';
import { FormattedMessage } from 'react-intl';

const IconBadge = props => {
  const { className, rootClassName, width = '2rem', height = '2rem', isVerified } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [isOpen, setIsOpen] = useState(false)
  const fill = props?.fill || (isVerified ? '#0077B5' : '#25D366') || '#027602';

  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (typeof window === 'object' && window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    handleResize()
  }, [])

  const dataTooltipId = `${isVerified ? 'verified' : 'unverified'}-tooltip-${Math.random().toString(36).slice(2)}`;
  const tooltipContent = isVerified ? <FormattedMessage id="IconBadge.verifiedContent" /> : <FormattedMessage id="IconBadge.unverifiedContent" />;
  const tooltipLearnMore = <FormattedMessage id="IconBadge.learnMore" values={{
    link: <a
      href='https://www.parnexus.com/p/kyc_aml_dd'
      className={css.tooltipLink}
      onClick={(event) => {
        event.stopPropagation();
        // Redirect to the specified link
        window.open('https://www.parnexus.com/p/kyc_aml_dd', '_blank');
      }}
      target='_blank'
    >here</a>
  }} />
  const isMobileDisplay = isMobile ? { isOpen: isOpen } : {}

  return (
    <>
      <span id={dataTooltipId} data-tooltip-place="top" onClick={(e) => {
        e.preventDefault()
        setIsOpen(!isOpen)
      }}>
        <svg
          className={classes}
          width={width}
          height={height}
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7663_6984)">
            <path
              d="M42.9986 6.74945C27.2944 -5.11591 12.1235 1.32085 6.50098 6.02241V30.8875C8.59486 40.8916 19.2 47.2702 24.2409 49.209C37.6185 43.7416 41.7384 34.7166 42.1262 30.8875L42.9986 6.74945Z"
              fill={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.4219 16.3964C32.2111 15.6605 33.4375 15.6683 34.2173 16.4142L35.8904 18.0146C36.7276 18.8154 36.7287 20.1524 35.8928 20.9545L23.2239 33.1115C22.4051 33.8972 21.1018 33.8617 20.327 33.0325L13.647 25.8837C12.8731 25.0555 12.9247 23.7547 13.7617 22.9905L15.4394 21.4587C16.2265 20.74 17.4348 20.7502 18.2098 21.4821L21.8655 24.9347C21.9771 25.0401 22.1513 25.0406 22.2635 24.936L31.4219 16.3964ZM33.0112 17.6752C32.8998 17.5686 32.7246 17.5675 32.6119 17.6726L23.4535 26.2122C22.6679 26.9447 21.4483 26.9408 20.6674 26.2033L17.0117 22.7506C16.901 22.6461 16.7284 22.6446 16.6159 22.7473L14.9383 24.2791C14.8187 24.3882 14.8113 24.5741 14.9219 24.6924L21.6019 31.8412C21.7126 31.9597 21.8988 31.9647 22.0158 31.8525L34.6846 19.6955C34.804 19.5809 34.8039 19.3899 34.6843 19.2755L33.0112 17.6752Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_7663_6984">
              <rect width="50" height="50" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <Tooltip
        className={css.mobileResponsive}
        {...isMobileDisplay}
        anchorSelect={'#' + dataTooltipId} clickable>
        {(isOpen || !isMobile) && <span
          onClick={(event) => {
            event.preventDefault();
          }}
        >
          {isMobile && <div className={css.closeButton} onClick={(e) => {
            e.preventDefault()
            setIsOpen(false)
          }}>&#x2715;</div>}
          <span>
            {
              tooltipContent
            }
          </span>
          <span>
            {
              tooltipLearnMore
            }
          </span>

        </span>}
      </Tooltip >
    </>
  );
};

IconBadge.defaultProps = { className: null };

IconBadge.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBadge;
