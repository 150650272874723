import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './ModalMissingInformation.module.css';

const DateOfBirthRequired = props => {
  const { className } = props;

  const profileSettingsPageLink = (
    <NamedLink className={css.helperLink} name="ProfileSettingsPage">
      <FormattedMessage id="ModalMissingInformation.profileSettingsLinkText" />
    </NamedLink>
  );

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.dobRequiredTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.dobRequiredText" />
      </p>
      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          <FormattedMessage
            id="ModalMissingInformation.dobRequiredMessage"
            values={{ profileSettingsPageLink }}
          />
        </p>
      </div>
    </div>
  );
};

export default DateOfBirthRequired;
